.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: sticky;
  top: 0;
  z-index: 999;
}

.App-link {
  color: #61dafb;
}

.header {
  margin:'0px';
  padding:'0px';
  border-bottom:'solid';
  background: linear-gradient(to right, #0C0854 50%, #2CCCD6 50%);
  /*background:#6b0752; */
}

main {
  min-height:95vh;
  padding:10px;
  margin:10px;
}


.dashbody {
  display: flex;
  /* height: 95vh; */
  background-color: "white";
  padding-top: -10px;
  margin-top: -10px;
}

.sidenav {
  width: 25vw;
  height: 95vh;
  border-style: None;
  /* padding: 10px;
  margin: 3px; */
  overflow: scroll;
  background-color: white;
}

.content {
  /* height: 95vh; */
  border-style: None;
  /* padding: 10px; */
  width: 75vw;
  overflow: scroll;
  background-color: white;
  /*#f2f1ec;*/
  /* margin: 3px; */
}


.button_gen {
  background-color:#61dafb;
}

.button_toolbar {
  background-color:#b861fb;
}

.button_2 {
  background-color:#771e16;
}

/* .colStyle {
  white-space: 'normal';
  word-wrap: 'break-word';
  width: '10vw';
  border:'solid';
  padding:'10px';
  margin:'10px';
  width:'20%';
} */

.tablestyle {

  border-collapse: collapse;
  border-spacing: 0;
  /* width: 100%; */
  border: 1px solid #ddd;
  overflow-x:auto;
}

th, td {
  text-align: left;
  padding: 8px;
  margin:10px;
  border: 1px solid;
  border-color: grey;
}

td {
  width: 10%;
  min-width:100px;
  font-weight: 600;
}


.chatwrapper {
  position: fixed;
  width: 70%;
  bottom: 0;
  margin-left: 10px;
  margin-bottom: 30px;

}

.tabwrapper {
  padding: 1px;
  /* height: 95%;
  position: fixed;
  overflow: scroll; */
}

.chatwrapperbrowser {
  position: fixed;
  width: 95%;
  bottom: 0;
  margin-left: 10px;
  margin-bottom: 30px;

}


.spreadsheet {
  position: relative;
}


.document{
  margin-bottom:5px; 
  border:solid; 
  border-color:#b861fb; 
  margin-bottom:5px; 
  border-color:#b861fb;
  width: 80vw;
  height: 80vh;

  /* height:70vh; 
  width:95vw;  */
}

.documentA4 {
  /* width: 80vw;
  height: 114vw; */
  /* width: 57vw;
  height: 80vw; */

  width: 210mm;
  height: 297mm;
}

.documentslide {
  width: 80vw;
  height: 45vw;
}

/* .tablemenu {
  position: fixed;
  width: 95%;
  top: 10;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color:'white';

} */


.projecthomebody {
  display: flex;
  /* height: 95vh; */
  background-color: "white";
  padding-top: -10px;
  margin-top: -10px;
}

.projecthomesidenav {
  width: 35vw;
  height: 95vh;
  border-style: None;
  /* padding: 10px;
  margin: 3px; */
  overflow: scroll;
  background-color: white;
}

.projecthomecontent {
  /* height: 95vh; */
  border-style: None;
  /* padding: 10px; */
  width: 65vw;
  overflow: scroll;
  background-color: white;
  /* margin: 3px; */
}


.container {
  width: 100%;
  margin: 0 auto;
}

/* header {
  background: linear-gradient(135deg, #7b2ff7, #f107a3);
  color: white;
  padding: 20px 0;
  text-align: center;
} */

section {
  padding: 50px 0;
  text-align: center;
}
.coming-soon {
  background: #f107a3;
  color: white;
  padding: 10px 20px;
  display: inline-block;
  margin-top: 20px;
  border-radius: 20px;
}
.ai-model {
  background: #e0d7f5;
  margin-bottom: 20px ;
  margin-left:0px;
  margin-right:0px;
  width:100vw;
}
.cta {
  background: #7b2ff7;
  color: white;
  padding: 30px 0;
}
.cta button {
  background: #f107a3;
  color: white;
  padding: 10px 30px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}
footer {
  background: #3f3d56;
  color: white;
  padding: 20px 0;
  text-align: center;
}



.card_badge {
  padding:5px;
  margin-bottom:5px;
  /* background-color:'#89de6f'!; */
  background-color:#0cc0df !important;
  color:white;
  border-radius:5px;
}


.snippetsbody {
  display: flex;
  height: 95vh;
  border-style: hidden;
}

.snippetssidenav {
  width: 25vw;
  height: 95vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 1px;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}


.snippetscontent {
  height: 95vh;
  border-style: none;
  border-width: 1px;
  padding: 10px;
  margin: 1px;
  width: 75vw;
  overflow: scroll;
  background-color: white;
  border-radius: 7px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
